import React from 'react'
import {
  BlockList,
  generateBackgroundStyles,
  generateColorThemeClasses,
  generateDarkenLightenStyles,
  generateForegroundStyles,
  generatePaddingClasses,
  generateTopRadiusClasses,
  SectionSettingsProps,
} from '.'

export const SectionFourColumnBlock = (props: SectionSettingsProps) => {
  var { areas } = props

  var leftArea = areas?.find(area => area.name === 'Left')
  var middleLeftArea = areas?.find(area => area.name === 'MiddleLeft')
  var middleRightArea = areas?.find(area => area.name === 'MiddleRight')
  var rightArea = areas?.find(area => area.name === 'Right')

  const backgroundStyles = generateBackgroundStyles(props)
  const darkenLightenStyles = generateDarkenLightenStyles(props)
  const colorThemeClasses = generateColorThemeClasses(props)
  const paddingClasses = generatePaddingClasses(props)
  const foregroundStyles = generateForegroundStyles(props)
  const topRadiusClasses = generateTopRadiusClasses(props)

  return (
    <div
      style={{ ...backgroundStyles, ...foregroundStyles }}
      className={`${colorThemeClasses} ${paddingClasses} ${topRadiusClasses}`}
      data-block-section>
      <div style={darkenLightenStyles}>
        <div className="container">
          <div className="row" data-block-section>
            {leftArea && (
              <div className="col-12 col-md-3">
                <BlockList blocks={leftArea.blocks} availableColumns={3} />
              </div>
            )}
            {middleLeftArea && (
              <div className="col-12 col-md-3">
                <BlockList blocks={middleLeftArea.blocks} availableColumns={3} />
              </div>
            )}
            {middleRightArea && (
              <div className="col-12 col-md-3">
                <BlockList blocks={middleRightArea.blocks} availableColumns={3} />
              </div>
            )}
            {rightArea && (
              <div className="col-12 col-md-3">
                <BlockList blocks={rightArea.blocks} availableColumns={3} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
